<template>
  <div class="container">
    <p class="title-text">企业认证审核</p>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待审核" name="first"><reviewed v-if="activeName === 'first'"></reviewed></el-tab-pane>
      <el-tab-pane label="已驳回" name="second"><rejected v-if="activeName === 'second'"></rejected></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import rejected from "./components/rejected";
import reviewed from "./components/reviewed";
export default {
  name: "index",
  components:{rejected,reviewed},
  data() {
    return {
      activeName: 'first'
    };
  },
  created() {
    this.$route.query?.activeName && (this.activeName = this.$route.query.activeName);
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin: 18px;
  padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
  .title-text{font-size: 18px;color: #242D33;position: relative;margin-bottom: 15px;
    &:before{position: absolute;content: '';left: -30px;top: 4px;width: 4px;height: 20px;background: #3984F5;}
  }
  // tags样式修改
  ::v-deep .el-tabs__nav-wrap::after{
    background: #EEEFF2;
    height: 1px;
  }
  ::v-deep .el-tabs__item.is-active{
    color: #3984F5;
    font-weight: bold;
  }
  ::v-deep .el-tabs__item{
    &:hover{
      color: #3984F5;
    }
  }
}
</style>