<template>
  <div>
    <div class="screen clearfix" style="margin-top: 10px">
      <div class="item">
        <span>企业名称</span>
        <div class="box">
          <el-input v-model="inquire.company" placeholder="输入企业名称" style="width: 210px"></el-input>
        </div>
      </div>

      <div class="item">
        <span>提交时间</span>
        <div class="box">
          <el-date-picker
              v-model="inquire.registrationTime"
              class="daterange"
              type="datetimerange"
              range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 234px"
              @change="handleChangeDate">
          </el-date-picker>
        </div>
      </div>
      <span class="button inquire" @click="inquireBtn">查询</span>
      <span class="button" @click="resetBtn">重置</span>
    </div>
    <div class="main_table">
      <el-table
          ref="multipleTable"
          :data="tableData"
          :border="true"
          v-loading="loading"
          style="width: 100%">
        <el-table-column
            label="序号"
            min-width="18%"
            style="text-align: center"
        >
          <template slot-scope="scope">
            <span>{{ (page - 1) * limit + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="company"
            label="企业名称">
        </el-table-column>
        <el-table-column
            prop="identification"
            label="统一社会信用代码">
        </el-table-column>
        <el-table-column
            prop="corporation"
            width="140"
            label="法定代表人姓名">
        </el-table-column>
        <el-table-column
            prop="create_time"
            width="160"
            label="提交时间"
            min-width="100%">
        </el-table-column>
        <el-table-column
            prop="update_time"
            width="160"
            label="审核时间"
            min-width="100%">
        </el-table-column>
        <el-table-column
            prop="note"
            label="驳回原因"
            min-width="100%">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <a style="color: #3984F5" href="JavaScript:;" @click="detailBtn(scope.row.id)">查看详情</a>
          </template>
        </el-table-column>
      </el-table>
      <div class="operate fr">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="limit"
            layout="prev, pager, next, sizes"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "rejected",
  data(){
    return {
      inquire:{
        registrationTime:[],
        company:''
      },
      tableData:[],
      total:0,
      limit:10,
      page:1,
      loading:false
    }
  },
  mounted(){
    this.getData();
  },
  methods:{
    // 获取列表数据
    getData(){
      const params = {
        page: this.page,
        limit: this.limit,
        company: this.inquire.company,
        start_time: this.inquire.registrationTime?.[0],
        end_time: this.inquire.registrationTime?.[1]
      }
      this.loading = true;
      this.$api.getEntRejectList(params).then(res => {
        this.tableData = res.data.list
        this.total = res.data.count
      }).finally(() => {
        this.loading = false
      })
    },
    handleChangeDate(){

    },
    inquireBtn(){
      this.page = 1;
      this.getData();
    },
    resetBtn(){
      this.inquire = {
        registrationTime: [],
        company: ''
      }
      this.page = 1;
      this.getData();
    },
    handleSizeChange(value){
      this.page = 1;
      this.limit = value;
      this.getData();
    },
    handleCurrentChange(value){
      this.page = value;
      this.getData();
    },
    detailBtn(id){
      this.$router.push({path: '/certificationDetails', query: {id: id}})
    }
  }
}
</script>
<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">

</style>